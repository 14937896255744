<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="上传ID:">
        <el-input
          v-model.trim="formInline.id"
          v-check-permission
          placeholder="请输入上传ID"
        />
      </el-form-item>
      <el-form-item label="患者姓名:">
        <el-input
          v-model.trim="formInline.patientName"
          v-check-permission
          placeholder="请输入患者姓名"
        />
      </el-form-item>
      <el-form-item label="预审状态:">
        <el-select v-model="formInline.status">
          <el-option
            v-for="item in medicalAuditStateList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="上传时间:">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="手机号码:">
        <el-input
          v-model="formInline.patientMobile"
          v-check-permission
          placeholder="请输入手机号码"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="clearData()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="id" label="上传ID" align="center" />
      <el-table-column prop="patientName" label="患者姓名" align="center" />
      <el-table-column prop="patientMobile" label="手机号码" align="center" />
      <el-table-column prop="status" label="预审状态" align="center">
        <template slot-scope="scope">
          <span>{{ medicalAuditStateDic[scope.row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="上传类型" align="center">
        <template slot-scope="scope">
          <span>{{ medicalType[scope.row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdDate" label="上传时间" align="center" />
      <el-table-column
        label="资料照片"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, scope.$index)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  medicalAuditStateDic,
  medicalType,
  medicalAuditStateList,
} from '@/utils/component/publicList.js';
import moment from 'moment';
export default {
  name: 'FollowUpManage',
  data() {
    return {
      medicalAuditStateDic: medicalAuditStateDic, // 预审状态字典
      medicalAuditStateList: medicalAuditStateList, // 预审状态列表
      medicalType: medicalType, // 上传类型字典
      loading: false, // 给列表加上loading效果
      formInline: {
        id: '',
        patientName: '',
        status: '',
        time: '',
        patientMobile: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getFollowUpList();
  },
  methods: {
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getFollowUpList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getFollowUpList();
    },
    // 重置
    clearData() {
      this.formInline = {
        // 查询条件
        id: '',
        patientName: '',
        status: '',
        time: '',
        patientMobile: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getFollowUpList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getFollowUpList();
    },
    // 分页查询用户套餐列表
    getFollowUpList() {
      this.loading = true;
      const { pageInfo, formInline } = this;
      const params = {
        ...formInline,
        startDate:
          formInline.time !== '' && formInline.time !== null
            ? moment(formInline.time[0]).format('YYYY-MM-DD')
            : '',
        endDate:
          formInline.time !== '' && formInline.time !== null
            ? moment(formInline.time[1]).format('YYYY-MM-DD')
            : '',
        page: {
          done: true,
          pageIndex: pageInfo.page,
          pageSize: pageInfo.size,
        },
      };
      this.$api
        .medicalPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, index) {
      var info = {
        patientName: item.patientName,
        patientMobile: item.patientMobile,
      };
      console.log(info, 'info');
      this.$router.push({
        name: 'medicalHistoryDetail',
        query: {
          id: item.id,
          index: index,
          params: JSON.stringify(this.formInline),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
